import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// import useDarkMode from '../hooks/useDarkMode';
import AvatarImg from '../img/home/pt-avatar.svg'
import PaintBrush from '../img/home/PaintBrush.svg'
import PaintBrush_dark from '../img/home/PaintBrush_dark.svg'

// import TSParticles from './particles';

import '../styles/hero.css';

const Hero = () => {
    const { t } = useTranslation();
    // const [darkTheme] = useDarkMode();
    return (
        <section id="home">
            {/* bg-pascal-lightblue dark:bg-gray-700 md:bg-transparent md:dark:bg-transparent  */}
            <div id="hero-content" class="py-20 h-auto tracking-wide">
                <div class="flex flex-col items-center pt-3 pb-5 ">
                    <h2 class="text-xl font-bold highlight dark:highlight_dark">{t('hero.name')}</h2>
                    {/* <h3 class="text-lg font-light">I'm a <span class="font-bold">21 year</span> old <span class="font-bold">SysAdmin</span> from Germany</h3> */}
                    <h3 class="text-lg font-light highlight dark:highlight_dark">{t('hero.intro.start')}<span class="font-bold">{t('hero.intro.age')}</span>{t('hero.intro.age.text')}<span class="font-bold">{t('hero.intro.sysadmin')}</span> {t('hero.intro.location')}</h3>
                </div>
                {/* <!-- Avatar --> */}
                <div class="flex-center" id="avatar">
                    <img src={AvatarImg} alt="" class="w-1/6 drop-shadow-xl" />
                </div>
                <div class="flex-center flex-col pt-10 pb-5">
                    <h3 class="text-lg highlight dark:highlight_dark">{t('hero.question')}</h3>
                    <h3 class="text-lg highlight dark:highlight_dark">{t('hero.followalong')}</h3>
                </div>
            </div>
            {/* <div id="particles-wrapper" class="hidden md:block">
                {darkTheme
                    ? <TSParticles value="#1f2937" />
                    : <TSParticles value="#98c1d9" />
                }
            </div> */}
        </section>
    )
}

export default Hero