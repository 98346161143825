import React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'

import DeFlag from '../img/home/flags/de.svg'
import EnFlag from '../img/home/flags/en.svg'
import Logo from '../img/home/Logo.svg'

import '../styles/themeicon.css'
import '../styles/navbar.css'
import '../styles/languageSwitcher.css'
import useDarkMode from '../hooks/useDarkMode'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const NavBar = ({ home, aboutme, timeline, experience, contact }) => {
  const { t } = useTranslation()

  return (
    <header class="sticky top-0 z-50 px-3 bg-white text-pascal-darkblue w-full dark:bg-gray-900 dark:text-white">
      <nav class="container flex items-center py-3">
        <div class="xl:text-3xl font-signika flex flex-row items-center justify-center pr-5">
          <img
            src={Logo}
            alt=""
            class="w-9 drop-shadow-xl pr-3 mr-3 border-r border-gray-400"
          />
          Pascal Traum&uuml;ller
        </div>
        <ul class="group hidden sm:flex flex-1 justify-end items-center gap-12 uppercase text-xs">
          <li class="hover:underline ">
            <Link to={home}>{t('navbar.home')}</Link>
          </li>
          <li class="hover:underline ">
            <Link to={aboutme}>{t('navbar.aboutme')}</Link>
          </li>
          <li class="hover:underline ">
            <Link to={timeline}>{t('navbar.timeline')}</Link>
          </li>
          <li class="hover:underline ">
            <Link to={experience}>{t('navbar.experience')}</Link>
          </li>
          <li class="hover:underline ">
            <Link to={contact}>{t('navbar.contact')}</Link>
          </li>
          <li class="relative text-left dropdown inline-block">
            <LanguageButton>
              <DropdownMenu />
            </LanguageButton>
          </li>
          <li class="ml-5">
            <ThemeIcon />
          </li>
        </ul>
      </nav>
    </header>
  )
}

// Button for the darkmode
const ThemeIcon = () => {
  const [darkTheme, setDarkTheme] = useDarkMode()
  const handleMode = () => setDarkTheme(!darkTheme)
  return (
    <button
      onClick={handleMode}
      onKeyDown={handleMode}
      className={`toggle${darkTheme ? ' night' : ''}`}
    >
      <div className="notch">
        <div className="crater" />
        <div className="crater" />
      </div>
      <div>
        <div className="shape sm" />
        <div className="shape sm" />
        <div className="shape md" />
        <div className="shape lg" />
      </div>
    </button>
  )
}

// The Button in the Navbar
const LanguageButton = (props) => {
  const { language } = useI18next()
  let imgTag = ''
  if (language === 'en') {
    imgTag = <img src={EnFlag} alt="current language: english" />
  } else if (language === 'de') {
    imgTag = <img src={DeFlag} alt="aktuelle Sprache: Deutsch" />
  } else {
    imgTag = <p>Current language unknown</p>
  }

  return (
    <div>
      <button
        class="rounded-full h-7 w-7 hover:opacity-75 cursor-pointer"
        aria-haspopup="true"
        aria-expanded="true"
        aria-controls="headlessui-menu-items-117"
      >
        <span class="nav-icon">{imgTag}</span>
      </button>
      {props.children}
    </div>
  )
}

// The Dropdown Menu that appears when clicking the LanguageButton
const DropdownMenu = () => {
  const { languages } = useI18next()
  return (
    <div className="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
      <div
        class="absolute h-4 w-4 transform rotate-45 bg-white z-10 border-l border-t border-gray-200"
        style={{ right: '6px' }}
        aria-hidden="true"
      ></div>
      <div
        className="absolute right-0 w-40 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
        aria-labelledby="headlessui-menu-button-1"
        id="headlessui-menu-items-117"
        role="menu"
      >
        <div class="py-2">{languages.map((lng) => DropdownItem({ lng }))}</div>
      </div>
    </div>
  )
}

// The single language item
const DropdownItem = (mappedLanguage) => {
  const { language, changeLanguage } = useI18next()
  // const cssClass = "text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left";
  const cssClass =
    'text-gray-700 flex items-center justify-evenly w-full px-4 py-2 mt-1 text-sm leading-5 text-left hover:bg-gray-200 shadow-inner disabled:opacity-50 disabled:cursor-default'
  let currentLangDE = false
  let currentLangEN = false
  if (language === 'de') {
    currentLangDE = true
  }
  if (language === 'en') {
    currentLangEN = true
  }

  if (mappedLanguage.lng === 'de') {
    return (
      <button
        className={cssClass}
        disabled={currentLangDE ? true : false}
        role="menuitem"
        onClick={(e) => {
          e.preventDefault()
          changeLanguage(mappedLanguage.lng)
        }}
      >
        <span className="icon-button">
          <img src={DeFlag} alt="" />
        </span>
        Deutsch
      </button>
    )
  } else if (mappedLanguage.lng === 'en') {
    return (
      <button
        className={cssClass}
        disabled={currentLangEN ? true : false}
        role="menuitem"
        onClick={(e) => {
          e.preventDefault()
          changeLanguage(mappedLanguage.lng)
        }}
      >
        <span className="icon-button">
          <img src={EnFlag} alt="" />
        </span>
        English
      </button>
    )
  } else {
    return <button className="menu-item">Unknown language found!</button>
  }
}

export default NavBar
