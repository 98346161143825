import React from 'react';
import Timeline from './timeline';
import Slider from './slider';

const Resume = () => {
    return (
        // <!-- CV Section -->
        <section class="bg-pascal-lightblue dark:bg-gray-700 flex-center flex-col text-center pb-5">
            <Timeline />
            <Slider />
        </section>
    )
}

export default Resume