import { useEffect, useRef } from 'react';
import anime from "animejs";

const useEffectAnime = () => {
    const animationRef = useRef(null);
    useEffect(() => {
        const infoLineEls = [
            document.getElementById('schoolInfoLineEl'),
            document.getElementById('schoolInternshipInfoLineEl'),
            document.getElementById('holidayInternshipInfoLineEl'),
            document.getElementById('apprenticeshipInfoLineEl'),
            document.getElementById('sysadminBwInfoLineEl'),
            document.getElementById('sysadminWinkemannInfoLineEl')
        ];
        const duration = 1500;

        animationRef.current = anime.timeline({
            easing: 'linear',
            duration: duration,
            autoplay: false
        });

        for (let i = 0; i < infoLineEls.length; i++) {
            let delay = 0;
            let direction = 1;
            let infoEl = null;

            var infoLineEl = infoLineEls[i];

            if (infoLineEl.id === 'schoolInfoLineEl') {
                delay = duration * 0.01;
                direction = 1;
                infoEl = document.getElementById('infoSchoolEl');
            }
            else if (infoLineEl.id === 'schoolInternshipInfoLineEl') {
                delay = duration * 0.38;
                direction = -1;
                infoEl = document.getElementById('infoSchoolInternshipEl');
            }
            else if (infoLineEl.id === 'holidayInternshipInfoLineEl') {
                delay = duration * 0.43;
                direction = -1;
                infoEl = document.getElementById('infoHolidayInternshipEl');
            }
            else if (infoLineEl.id === 'apprenticeshipInfoLineEl') {
                delay = duration * 0.51;
                direction = 1;
                infoEl = document.getElementById('infoApprenticeshipEl');
            }
            else if (infoLineEl.id === 'sysadminBwInfoLineEl') {
                delay = duration * 0.80;
                direction = -1;
                infoEl = document.getElementById('infoSysadminBwEl');
            }
            else if (infoLineEl.id === 'sysadminWinkemannInfoLineEl') {
                delay = duration * 0.99;
                direction = 1;
                infoEl = document.getElementById('infoSysadminWinkemannEl');
            }

            animationRef.current
                .add({
                    targets: infoLineEl,
                    scaleY: [0, 100],
                    duration: 100,
                    easing: 'easeOutCirc',
                }, delay)
                .add({
                    targets: infoEl,
                    opacity: [0, 1],
                    translateY: [direction * 10, 0],
                    duration: 50,
                    delay: anime.stagger(50, { start: 10, direction: direction > 0 ? 'reverse' : 'normal' }),
                    easing: 'easeOutSine'
                }, delay);

            const timelineCursorEl = document.getElementById('timeline-cursor');
            timelineCursorEl.oninput = function () {
                
                const timelineWrapperEl = document.getElementById('timeline-wrapper');
                if (timelineCursorEl.value > 0) {
                    timelineWrapperEl.classList.add('pt-32');
                    timelineWrapperEl.classList.add('pb-28');
                }
                else {
                    timelineWrapperEl.classList.remove('pt-32');
                    timelineWrapperEl.classList.remove('pb-28');
                }
                animationRef.current.seek(animationRef.current.duration * (timelineCursorEl.value / 100));
            };
        }
    });
}

export default useEffectAnime;