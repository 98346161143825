import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../styles/contact.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faXing, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const Contact = () => {

    const { t } = useTranslation();

    return (
        // <!-- Contact Section -->
        <div class="bg-pascal-darkblue dark:bg-gray-800">
            {/* <img src={PopsicleImg} alt="" class="bg-pascal-lightblue" /> */}
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px"
                y="0px" viewBox="0 0 500 78.3">
                <path class="popsicle-wave-path" d="M500,18.6c-35.2,0-8.5,59.6-32.5,59.6S465.1,26,445.9,26s-2.4,44.7-22.8,44.7s-1.3-63.8-23.6-64
                        c-16.7-0.2-5.8,58.9-26.3,58.9c-23.2,0-0.6-36.8-21.4-36.8s-9.8,48.9-27.3,48.9s9.2-56.7-17.5-56.7s2.9,51.5-23.1,51.5
                        s-0.9-33.2-23.4-33.2c-17.6,0-11.2,16.3-24.2,16.3c-22.3,0,0.1-45.4-23.6-45.4s3.6,60-22.4,60s-1.9-49.7-22-49.7
                        s-4.9,41.2-22.6,41.2c-22,0,1.3-48.8-21.8-48.8c-23.8,0,0.8,54.2-27.2,54.2C73,67,92.6,13.6,69.2,13.4c-28-0.3-0.8,58.3-27.6,58.3
                        S33.9,9.1,11.9,9.1C0.7,9.1,0,23.2,0,23.2V0h500V18.6z" />
            </svg>
            {/* <!-- Form Card --> */}
            {/* <!-- TODO: Configure Contact Form --> */}
            <div id="contact" class="flex-center flex-col text-lg">
                <div id="contact-form-card" class="bg-pascal-gray flex-center flex-col py-4 mt-32 mb-16 w-10/12 rounded-xl">
                    <p>{t('contact.title')}</p>
                    <p>{t('contact.subtitle')}</p>
                    <hr class="border-gray-700 border w-4/5 my-2" />
                    <form id="contact-form" action="../php/contact.php" method="POST" class="bg-pascal-orange dark:bg-gray-500 rounded-xl w-4/5 px-8 mt-6 pt-6 pb-4 mb-10">
                        <div class="mb-4">
                            <label class="form-label" htmlFor="first_name">
                            {t('contact.firstname')} <span class="required_field">*</span>
                            </label>
                            <input class="form-input" id="first_name" name="first_name" type="text" placeholder={t('contact.firstname')} required />
                        </div>
                        <div class="mb-4">
                            <label class="form-label" htmlFor="last_name">
                            {t('contact.lastname')}
                            </label>
                            <input class="form-input" id="last_name" name="last_name" type="text" placeholder={t('contact.lastname')} />
                        </div>
                        <div class="flex flex-wrap mb-6">
                            <div class="w-full md:w-1/2 pr-3">
                                <label class="form-label" htmlFor="phone">
                                {t('contact.phone')}
                                </label>
                                <input class="form-input" id="phone" name="phone" type="tel" placeholder="+49 174 123 456 78" />
                            </div>
                            <div class="w-full md:w-1/2">
                                <label class="form-label" htmlFor="company">
                                {t('contact.company')}
                                </label>
                                <input class="form-input" id="company" name="company" type="text" placeholder={t('contact.company')} />
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="form-label" htmlFor="email">
                            {t('contact.email')} <span class="required_field">*</span>
                            </label>
                            <input class="form-input" id="email" name="email" type="email" placeholder={t('contact.email')} required />
                        </div>
                        <div class="mb-4">
                            <label class="form-label" htmlFor="subject">
                            {t('contact.subject')} <span class="required_field">*</span>
                            </label>
                            <input class="form-input" id="subject" name="subject" type="text" placeholder={t('contact.subject')} required />
                        </div>
                        <div class="mb-4">
                            <label class="form-label" htmlFor="message">
                            {t('contact.message')} <span class="required_field">*</span>
                            </label>
                            <textarea class="form-input" id="message" name="message" type="text-area" placeholder={t('contact.message')} required></textarea>
                        </div>
                        <div>
                            <input id="submit-button" type="submit" class="bg-pascal-darkblue dark:bg-gray-800 hover:bg-pascal-lightblue uppercase rounded px-8 py-1" value={t('contact.submit')} />
                        </div>
                    </form>
                </div>
            </div>
            {/* <!-- end Form Card --> */}
            <div id="socials" class="flex flex-1 justify-center items-center flex-row pb-5 gap-5 text-sm">
                <div id="linkedin" class="fa-stack fa-2x">
                    <a href="https://www.linkedin.com/in/pascal-traum%C3%BCller-89158b197/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faCircle} className="text-pascal-orange dark:text-gray-500 fa-stack-2x" />
                        <FontAwesomeIcon icon={faLinkedinIn} className="text-pascal-gray fa-stack-1x" />
                    </a>
                </div>
                <div id="xing" class="fa-stack fa-2x">
                    <a href="https://www.xing.com/profile/Pascal_Traumueller/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faCircle} className="text-pascal-orange dark:text-gray-500 fa-stack-2x "/>
                        <FontAwesomeIcon icon={faXing} className="text-pascal-gray fa-stack-1x" />
                    </a>
                </div>
                <div id="email" class="fa-stack fa-2x">
                    <a href="mailto:pascal.traumueller@t-online.de" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faCircle} className="text-pascal-orange dark:text-gray-500 fa-stack-2x" />
                        <FontAwesomeIcon icon={faEnvelope} className="text-pascal-gray fa-stack-1x" />
                    </a>
                </div>
            </div>
        </div>
        // {/* <!-- end Contact Section --> */ }
    )
}

export default Contact