import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import NavBar from '../components/navbar'
import Hero from '../components/hero'
import AboutMe from '../components/aboutme'
import Resume from '../components/resume'
import Contact from '../components/contact'
import Footer from '../components/footer'
import ScrollToTop from '../components/scrolltotop'
import useDarkMode from '../hooks/useDarkMode'

const IndexPage = () => {
  const [darkTheme] = useDarkMode()
  return (
    <>
      {darkTheme ? (
        <Helmet
          bodyAttributes={{
            class: 'font-oswald text-white dark',
          }}
        />
      ) : (
        <Helmet
          bodyAttributes={{
            class: 'font-oswald text-white',
          }}
        />
      )}
      <Helmet>
        <title>Pascal Traum&uuml;ller | Portfolio</title>
      </Helmet>

      <div id="content-wrapper">
        <NavBar
          home="#home"
          aboutme="#aboutme"
          timeline="#timeline"
          experience="#experience"
          contact="#contact"
        />
        <Hero />
        <AboutMe />
        <Resume />
        <Contact />
        <Footer />
        <ScrollToTop />
      </div>
    </>
  )
}
export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
