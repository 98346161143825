import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../styles/slider.css'
import '@splidejs/splide/dist/css/splide.min.css';

import AerohiveImg from '../img/home/slider/aerohive.svg'
import AndroidImg from '../img/home/slider/android.svg'
import AppleImg from '../img/home/slider/apple.svg'
import CheckMKImg from '../img/home/slider/checkmk.svg'
import CSS3Img from '../img/home/slider/css3.svg'
import DellEmcImg from '../img/home/slider/dell_emc.svg'
import DellImg from '../img/home/slider/dell.svg'
import HTML5Img from '../img/home/slider/html5.svg'
import MicrosoftExchangeImg from '../img/home/slider/microsoft_exchange.svg'
import MicrosoftTeamsImg from '../img/home/slider/microsoft_teams.svg'
import Microsoft365Img from '../img/home/slider/ms365.svg'
import PaloAltoImg from '../img/home/slider/paloalto.svg'
import PythonImg from '../img/home/slider/python.svg'
import SamsungImg from '../img/home/slider/samsung.svg'
import SophosMobileControlImg from '../img/home/slider/sophos_mobile.svg'
import TailwindCSSImg from '../img/home/slider/tailwindcss.svg'
import VeeamImg from '../img/home/slider/veeam.svg'
import VMWareImg from '../img/home/slider/vmware.svg'

const Slider = () => {

    const { t } = useTranslation();

    return (
        // <!-- Experience Slider -->
        <section id="experience" class="py-24 flex flex-1 flex-col gap-4 w-11/12 items-center justify-center text-lg">
            <p>
                {t('slider.title')}
            </p>
            <div class="bg-gray-800 rounded-xl flex flex-1 justify-center items-center w-full">
                {/* <!-- TODO: Add Info on Hover over Slider Image --> */}
                <Splide
                    options={{
                        type: 'loop',
                        autoplay: true,
                        arrows: true,
                        pagination: false,
                        pauseOnFocus: false,
                        width: '100%',
                        padding: { left: '3.5rem' },
                        gap: '2rem',
                        easing: 'ease-in-out',
                        interval: 1000,
                        perMove: 1,
                        mediaQuery: 'min',
                        perPage: 3,
                        breakpoints: {
                            786: {
                                perPage: 6
                            }
                        }
                    }}>
                    {/* <!-- Software Icons --> */}
                    <SplideSlide>
                        <img src={CheckMKImg} alt="CheckMK" title="CheckMK" class="w-44"/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={VMWareImg} alt="VMWare" title="VMWare" class="w-44" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={VeeamImg} alt="Veeam Backup" title="Veeam Backup" class="w-44" />
                    </SplideSlide>
                    {/* <!-- Hardware Icons --> */}
                    <SplideSlide>
                        <img src={DellImg} alt="Dell" class="w-32" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={PaloAltoImg} alt="PaloAlto" title="PaloAlto" class="w-44" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={AerohiveImg} alt="AeroHive" title="AeroHive" class="w-44" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={DellEmcImg} alt="Dell EMC" title="Dell EMC" class="w-44" />
                    </SplideSlide>
                    {/* <!-- Microsoft Icons --> */}
                    <SplideSlide>
                        <img src={Microsoft365Img} alt="Microsoft 365" title="Microsoft 365" class="w-44" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={MicrosoftExchangeImg} alt="Microsoft Exchange" title="Microsoft Exchange" class="w-20" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={MicrosoftTeamsImg} alt="Microsoft Teams" title="Microsoft Teams" class="w-20" />
                    </SplideSlide>
                    {/* <!-- Mobile Icons --> */}
                    <SplideSlide>
                        <img src={AndroidImg} alt="Android" title="Android" class="w-24" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={SamsungImg} alt="Samsung" title="Samsung" class="w-44" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={SophosMobileControlImg} alt="Sophos Mobile Control" title="Sophos Mobile Control" class="w-24" />
                    </SplideSlide>
                    {/* <!-- Development Icons --> */}
                    <SplideSlide>
                        <img src={AppleImg} alt="Apple" title="Apple" class="w-20" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={PythonImg} alt="Python" title="Python" class="w-20" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={HTML5Img} alt="HTML5" title="HTML5" class="w-20" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={CSS3Img} alt="CSS3" title="CSS3" class="w-20" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={TailwindCSSImg} alt="Tailwind CSS" title="Tailwind CSS" class="w-24" />
                    </SplideSlide>
                </Splide>
            </div>
        </section >
        // {/* <!-- end Experience Slider --> */}
    )
}

export default Slider