import React from 'react';
import Anime from '../hooks/useEffectAnime'
import '../styles/timeline.css'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Timeline = () => {
    Anime()
    const { t } = useTranslation();

    return (
        // <!-- CV Section -->
        <div id="cv-section" class="w-full flex-center">
            {/* Timeline for large desktops */}
            <section class="bg-pascal-lightblue dark:bg-gray-700 p-5 w-11/12 h-auto rounded-b-xl pb-5 shadow-2xl hidden 2xl:block">
                <div id="timeline-wrapper" class="flex flex-1 items-center justify-center flex-col pb-5 transform duration-500 ease-in-out">
                    {/* <!-- Wrapper for the width --> */}
                    <div class="w-10/12">
                        <input type="range" id="timeline-cursor" name="timeline-cursor" min="0" max="100" step="0.5" defaultValue="0" className="timeline-cursor w-full cursor-grab active:cursor-grabbing" />
                        {/* <!-- The actual timeline below the ruller --> */}
                        <div id="infos" class="mr-2 text-sm">
                            {/* <!-- First row of timeline bars --> */}
                            <div class="flex flex-1 flex-row">
                                {/* <!-- 
                                ------------------------------------------------------------
                                Gesamt: 11 Jahre - 30.08.2010 - 01.01.2022 - 4142 Tage
                                ------------------------------------------------------------------------
                                    Schule: 30.08.2010 - 24.06.2016 - 2125 Tage - 51%
                                    ------------------------------------------------------------
                                    Schule: 30.08.2010 - 26.01.2015 - 1609 Tage - 76%
                                        Pflichtpraktikum: 26.01.2015 - 13.02.2015 - 19 Tage - 1%
                                        Schule: 14.02.2015 - 28.06.2015 - 135 Tage - 6%
                                        Frewilliges Praktikum: 29.06.2015 - 17.07.2015 - 19 Tage - 1%
                                        Schule: 18.07.2015 - 24.06.2016 - 343 Tage - 16%
                                ------------------------------------------------------------------------
                                    Berufsleben: 01.08.2016 - 01.01.2022 - 1979 Tage - ~49%
                                    ------------------------------------------------------------
                                        Ausbildung: 01.08.2016 - 10.07.2019 - 1073 Tage - 54%
                                        Arbeit BW: 11.07.2019 - 31.10.2021 - 844 Tage - 43%
                                        Arbeit Winkemann: 01.11.2021 - X - 3%
                                ------------------------------------------------------------
                                --> */}
                                <div id="schoolTime" class="flex flex-1 flex-row" style={{ width: '51%' }}>
                                    {/* <!-- Realschule --> */}
                                    <div id="school-1" class="relative rounded-l-full h-3 bg-pascal-orange dark:bg-gray-500 ml-2.5" style={{ width: '76%' }}>
                                        <div id="schoolInfoLineEl" class="info-line top-line-large"></div>
                                        <article id="infoSchoolEl" class="info-text top-right-text -top-52">
                                            <span class="font-bold underline">
                                                <h1>30.08.2010 - 24.06.2016</h1>
                                            </span>
                                            <br />
                                            <h2>Mittlere Reife - Geschwister-Scholl-Realschule - Plettenberg</h2>
                                            <br />
                                            {t('timeline.realschule')}
                                        </article>
                                    </div>
                                    {/* <!-- school internship --> */}
                                    <div id="holiday-internship" class="relative w-20 h-3 bg-pascal-gray" style={{ width: '1%' }}>
                                        <div id="schoolInternshipInfoLineEl" class="info-line bottom-line"></div>
                                        <article id="infoSchoolInternshipEl" class="info-text bottom-left-text">
                                            <section>
                                                <span class="font-bold underline">
                                                    <h1>26.01.2015 - 13.02.2015</h1>
                                                </span>
                                                <br />
                                                <h2>{t('timeline.schoolInternship.title')}</h2>
                                                <br />
                                                {t('timeline.schoolInternship')}
                                            </section>
                                        </article>
                                    </div>
                                    <div id="school-2" class="w-7/12 h-3 bg-pascal-orange dark:bg-gray-500" style={{ width: '6%' }}></div>
                                    {/* <!-- Summer internship --> */}
                                    <div id="school-internship" class="relative w-14 h-3 bg-pascal-gray" style={{ width: '1%' }}>
                                        <div id="holidayInternshipInfoLineEl" class="info-line bottom-line"></div>
                                        <article id="infoHolidayInternshipEl" class="info-text bottom-right-text">
                                            <span class="font-bold underline">
                                                <h1>29.06.2015 - 17.07.2015</h1>
                                            </span>
                                            <br />
                                            <h2>{t('timeline.summerInternship.title')}</h2>
                                            <br />
                                            {t('timeline.summerInternship')}
                                        </article>
                                    </div>
                                    <div id="school-3" class="rounded-r-full w-7/12 h-3 bg-pascal-orange dark:bg-gray-500" style={{ width: '16%' }}></div>
                                </div>
                                <div id="workTime" class="flex flex-1 flex-row" style={{ width: '49%' }}>
                                    <div id="apprenticeship" class="relative rounded-full h-3 bg-pascal-darkblue ml-2.5" style={{ width: '54%' }}>
                                        <div id="apprenticeshipInfoLineEl" class="info-line top-line-small"></div>
                                        {/* <!-- Apprenticeship BURG-WÄCHTER --> */}
                                        <article id="infoApprenticeshipEl" class="info-text top-right-text -top-32">
                                            <span class="font-bold underline">
                                                <h1>01.08.2016 - 10.07.2019</h1>
                                            </span>
                                            <br />
                                            <h2>{t('timeline.apprenticeship')}</h2>
                                        </article>
                                    </div>
                                    <div id="sysadmin-bw" class="relative rounded-full h-3 bg-pascal-gray ml-2.5" style={{ width: '43%' }}>
                                        <div id="sysadminBwInfoLineEl" class="info-line bottom-line"></div>
                                        {/* <!-- SysAdmin BURG-WÄCHTER --> */}
                                        <article id="infoSysadminBwEl" class="info-text bottom-right-text">
                                            <span class="font-bold underline">
                                                <h1>11.07.2019 - 31.10.2021</h1>
                                            </span>
                                            <br />
                                            <h2>{t('timeline.sysadminBw')}</h2>
                                        </article>
                                    </div>
                                    <div id="sysadmin-winkemann" class="relative rounded-full h-3 bg-pascal-orange dark:bg-gray-500 ml-2.5" style={{ width: '3%' }}>
                                        <div id="sysadminWinkemannInfoLineEl" class="info-line top-line-small"></div>
                                        <article id="infoSysadminWinkemannEl" class="info-text top-left-text -top-32">
                                            <span class="font-bold underline">
                                                <h1>{t('timeline.sysadminWinkemann.title')}</h1>
                                            </span>
                                            <br />
                                            <h2>{t('timeline.sysadminWinkemann')}</h2>
                                        </article>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- end of CV Infos at the bottom --> */}
                    </div>
                    {/* <!-- end of wrapper for the width --> */}
                </div>
                {/* <!-- end Timeline for large desktops--> */}
            </section>
            {/* <!-- Timeline for small desktops --> */}
            <section class="bg-pascal-lightblue dark:bg-gray-700 p-5 w-11/12 h-auto rounded-b-xl pb-5 shadow-2xl 2xl:hidden">
                <section id="timeline" class="relative w-full mx-auto px-6 flex flex-col space-y-8 text-gray-900">
                    {/* <!-- The horizontal line --> */}
                    <div class="absolute z-0 w-2 h-full bg-pascal-darkblue dark:bg-pascal-gray rounded-full shadow-md inset-0 ml-1 left-16 md:mx-auto md:right-0 md:left-0"></div>
                    <section class="relative z-10">
                        <h1 class="timeline-circle">2010</h1>
                        <article class="timeline-article timeline-article-right">
                            <div class="timeline-marker timeline-marker-right" aria-hidden="true"></div>
                            <div class="timeline-text">
                                <h2 class="font-bold underline">Mittlere Reife - Geschwister-Scholl-Realschule - Plettenberg</h2>
                                {t('timeline.realschule')}
                            </div>
                        </article>
                    </section>

                    <section class="relative z-10">
                        <h1 class="timeline-circle">2015</h1>
                        <article class="timeline-article timeline-article-left">
                            <div class="timeline-marker timeline-marker-left" aria-hidden="true"></div>
                            <div class="timeline-text">
                                <h2 class="font-bold underline">26.01.2015 - 13.02.2015</h2>
                                {t('timeline.schoolInternship.title')}
                                <br />
                                {t('timeline.schoolInternship')}
                            </div>
                        </article>
                    </section>

                    <section class="relative z-10">
                        <h1 class="timeline-circle">2015</h1>
                        <article class="timeline-article timeline-article-right">
                            <div class="timeline-marker timeline-marker-right" aria-hidden="true"></div>
                            <div class="timeline-text">
                                <h2 class="font-bold underline">29.06.2015 - 17.07.2015</h2>
                                {t('timeline.summerInternship.title')}
                                <br />
                                {t('timeline.summerInternship')}
                            </div>
                        </article>
                    </section>

                    <section class="relative z-10">
                        <h1 class="timeline-circle">2016</h1>
                        <article class="timeline-article timeline-article-left">
                            <div class="timeline-marker timeline-marker-left" aria-hidden="true"></div>
                            <div class="timeline-text">
                                <h2 class="font-bold underline">01.08.2016 - 10.07.2019</h2>
                                {t('timeline.apprenticeship')}
                            </div>
                        </article>
                    </section>

                    <section class="relative z-10">
                        <h1 class="timeline-circle">2019</h1>
                        <article class="timeline-article timeline-article-right">
                            <div class="timeline-marker timeline-marker-right" aria-hidden="true"></div>
                            <div class="timeline-text">
                                <h2 class="font-bold underline">11.07.2019 - 31.10.2021</h2>
                                {t('timeline.sysadminBw')}
                            </div>
                        </article>
                    </section>

                    <section class="relative z-10">
                        <h1 class="timeline-circle">2021</h1>
                        <article class="timeline-article timeline-article-left">
                            <div class="timeline-marker timeline-marker-left" aria-hidden="true"></div>
                            <div class="timeline-text">
                                <h2 class="font-bold underline">{t('timeline.sysadminWinkemann.title')}</h2>
                                
                                {t('timeline.sysadminWinkemann')}
                            </div>
                        </article>
                    </section>
                </section>
            </section>
        </div>
    )
}
export default Timeline