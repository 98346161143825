import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  return (
    // <!-- Footer Section -->
    <footer class="bg-pascal-gray text-white flex flex-1 items-center justify-around w-full py-2 shadow-inner">
      <p class="py-1">{t('footer.message')} Pascal Traum&uuml;ller</p>
    </footer>
    // {/* <!-- end Footer Section --> */ }
  )
}

export default Footer
