import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', toggleVisible)
  }

  return (
    <button
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
      class="fixed bottom-2 right-5 z-50 rounded-md text-xl font-bold w-10 h-10 bg-stp_red bg-pascal-gray hover:bg-red-400"
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  )
}

export default ScrollToTop
